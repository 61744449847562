import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import ApiConfigs from "src/ApiConfig/ApiConfig";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});

  const getUserProfileDatahandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfigs.getProfile,
        headers: {
          token: token,
        },
      });
      if (res.data.statusCode === 200) {
        setUserData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
      // if (error.response) {
      // eslint-disable-next-line
      if (error.response.data.statusCode == 401) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("tokens");
        // history.push("/");
      }
      // }
    }
  };

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if (token) {
      getUserProfileDatahandler(token);
    } // eslint-disable-next-line
  }, [window.localStorage.getItem("token")]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
