import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  loginLayoutBox: {
    background: theme.palette.background.white,
    display: "flex",
    zIndex: "9",
    position: "relative",
    justifyContent: "center",
    height: "100vh",
    "& .loginLayoutBoxImg": {
      width: "55%",
      zIndex: "1",
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100vh",
      left: 0,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& h1": {
        fontSize: "65px",
      },
    },
  },
  loginContentLayoutBox: {
    background: "#ffffff12",
    position: "absolute",
    right: 0,
    width: "45%",
    height: "calc(100vh - 0px)",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "65px 0 50px",
      height: "calc(100vh - 115px)",
    },
  },
  MainLayout: {
    minHeight: "calc(100vh - 72px)",
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <Box className={classes.loginLayoutBox}>
      <Box className="loginLayoutBoxImg">
        <Box align="center">
          <Typography variant="h4" style={{ color: "#fff" }}>
            Welcome to
          </Typography>
          <Box mt={1} mb={2}>
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <figure style={{ margin: "0px" }}>
                <img
                  src="images/logo.png"
                  alt="Logo Image"
                  style={{ maxWidth: "300px" }}
                />
              </figure>
            </RouterLink>
          </Box>
        </Box>
      </Box>
      <Box className={classes.loginContentLayoutBox}>
        <Box style={{ width: "100%" }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
