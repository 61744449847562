import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";
import SettingsContext from "src/context/SettingsContext";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    backgroundRepeat: "repeat",
    background: "#fff",
    backgroundImage: "url(/images/light.jpg)" /* fallback */,
    backgroundSize: "100%",
    backgroundPosition: "top",
  },
  root1: {
    backgroundColor: "#000",
    backgroundRepeat: "repeat",
    background: "#000",
    backgroundImage: "url(/images/dark.jpg)" /* fallback */,
    backgroundSize: "100%",
    backgroundPosition: "top",
  },
  MainLayout: {
    zIndex: "1",
    position: "relative",
    minHeight: "calc(100vh - 415px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <TopBar />

      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}> {children}</div>
      {/* <Update /> */}
      <Footer />
    </div>
  );
};

export default MainLayout;
