import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  Box,
  Badge,
  Avatar,
  Typography,
  SvgIcon,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 80,
    padding: "0 20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 3px 3px 3px",
    backdropFilter: "blur(44px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0 10px",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  // useEffect(() => {
  //   if (!window.localStorage.getItem("token")) {
  //     history.push("/");
  //   } // eslint-disable-next-line
  // }, [window.localStorage.getItem("token")]);
  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="40px">
              <MenuIcon style={{ color: "#712bc1" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        {/* <Hidden lgDown> */}
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Typography variant="h6">
              {`${auth.userData.firstName} ${auth.userData.lastName}`}
            </Typography>
            <Typography variant="body1">{`${auth.userData.email}`}</Typography>
          </Box>
          <IconButton onClick={(e) => history.push("/settings")}>
            <Badge className={classes.namePlace} color="secondary">
              <Avatar src="/images/Profile.png" alt="image" />
            </Badge>
          </IconButton>
        </Box>
        {/* </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
