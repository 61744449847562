import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";

import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiDialog: {
      paperFullWidth: {
        boxShadow:
          "0 2px 0 0 #ec0066, 0 -1px 0 0 #7e46a1, 2px 0 0 0 #7101bc, -2px 0 0 0 #ff4237, 1px 1px 0 0 #ec0066, -2px 2px 0 0 #ec0066, 2px 1px 0 0 #ec0066, -1px -2px 0 0 #ec0066",
        background: "#222222f2",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
        "&:hover": {
          "&:not(.Mui-disabled)": {
            "&::before": {
              borderBottom: "none",
            },
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        "& .Mui-disabled": {
          color: "#dae0e5",
        },
      },
      fullWidth: { width: "100%", height: "45px", paddingLeft: "10px" },
    },
    MuiOutlinedInput: {
      input: {
        padding: "14px",
      },
      adornedStart: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        background: "#ffffff12",
        borderRadius: "5px",
      },
    },
    MuiAppBar: {
      colorDefault: {
        color: "#FFFFFF",
        backgroundColor: "rgba(255, 255, 255, 0.03)",
      },
    },
    MuiTableCell: {
      root: {
        padding: "7px",
        fontSize: "12px",
        borderBottom: "none",
      },
      head: {
        fontWeight: "200",
        fontSize: "14px",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        paddingLeft: "14px",
      },
    },

    MuiButton: {
      root: {
        "&.Mui-disabled": {
          color: "rgb(112, 107, 107)",
        },
      },
      contained: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "5px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlined: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "50px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlinedSizeLarge: {
        padding: "7px 35px",
      },
      containedSizeLarge: {},
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#050404",
        },
      },
      MuiTableCell: {
        body: {
          color: "#000",
        },
      },
      MuiMenu: {
        list: {
          outline: "0",
          background: "#ffffff",
        },
      },
      MuiButton: {
        containedPrimary: {
          color: "#fff",
          filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
          padding: "10px 35px",
          fontSize: "14px",
          background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
          fontWeight: "500",
          lineHeight: "21px",
          // marginTop: "10px",
          borderRadius: "50px",
          backgroundColor: "#898989",

          "&:hover": {
            color: "#000",
            background: "transparent",
            boxShadow:
              "0 1px 0 0 #fe5aeb, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #fe5aeb, -1px 0 0 0 rgb(254 90 235), 1px -1px 0 0 #f4a91b, -1px 1px 0 0 rgb(254 90 235), 1px 1px 0 0 rgb(254 90 235), -1px -1px 0 0 rgb(244 168 26)",
            backgroundColor: "transparent",
          },
        },
        containedSecondary: {
          backgroundColor: "rgba(0, 0, 0, 0.03);",
          padding: "8px 27px",
          filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "21px",
          color: "#000000",
          borderRadius: "50px",
          border: "2px solid ",
          borderColor: "rgba(0, 0, 0, 0.03);",
          "&:hover": {
            color: "#000",
            background: "transparent",
            boxShadow:
              "0 1px 0 0 #fe5aeb, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #fe5aeb, -1px 0 0 0 rgb(254 90 235), 1px -1px 0 0 #f4a91b, -1px 1px 0 0 rgb(254 90 235), 1px 1px 0 0 rgb(254 90 235), -1px -1px 0 0 rgb(244 168 26)",
            backgroundColor: "transparent",
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.03) ",
          },
        },
        outlinedPrimary: {
          color: "#000",
          border: "1px solid #000 !important",
          "&:hover": {
            color: "#fff",
            boxShadow: "none !important",
            backgroundColor:
              "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
            // backgroundColor: "#51ACED !important",
            // border: "1px solid #51ACED !important",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "#000000",
        },
      },
    },
    typography: {
      // fontFamily: "'K2D', sans-serif"
    },
    palette: {
      background: {
        sellercard: "#fff",
        cardinner: "#fff",
        card: "#F7F7F7",
        tabButton: "#fff",
        default: "#cccccc",
        gradientbox: "#cccccc",
      },
      primary: {
        main: "#000000", //black
      },
      secondary: {
        main: "#000000", //black
        icons: "#009900", //white
      },
      text: {
        primary: "#000", //black
        secondary: "#000", //white
        gray: "rgba(0, 0, 0, 0.6)",
        graydark: "rgba(13, 13, 13, 0.3)",
      },
    },
  },
  {
    name: "DARK",
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#050404",
        },
      },
      MuiTableCell: {
        body: {
          color: "rgba(255, 255, 255, 0.5)",
        },
      },
      MuiMenu: {
        list: {
          outline: "0",
          background: "#191919",
        },
      },
      MuiButton: {
        containedPrimary: {
          color: "#fff",
          filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
          padding: "10px 35px",
          fontSize: "14px",
          background:
            "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",
          fontWeight: "500",
          lineHeight: "21px",

          borderRadius: "50px",
          backgroundColor: "#898989",

          "&:hover": {
            color: "#ffffff",
            background: "transparent",
            boxShadow:
              "0 1px 0 0 #ff00cd, 0 -1px 0 0 #7d00b9, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
            backgroundColor: "transparent",
          },
        },
        containedSecondary: {
          backgroundColor: "rgba(255, 255, 255, 0.04)",
          padding: "8px 27px",
          filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "21px",
          color: "#ffffff",
          borderRadius: "50px",
          border: "2px solid ",
          borderColor: "rgba(255, 255, 255, 0.04)",
          "&:hover": {
            color: "#ffffff",
            background: "transparent",
            boxShadow:
              "0 1px 0 0 #ff00cd, 0 -1px 0 0 #7d00b9, 1px 0 0 0 #f5673f, -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f, 1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f",
            backgroundColor: "transparent",
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.025) ",
            color: "#ffffff45",
          },
        },
        outlinedPrimary: {
          border: "1px solid rgba(255, 255, 255, 0.1) !important",
          "&:hover": {
            color: "#fff",
            boxShadow: "none !important",
            backgroundColor:
              "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
            // border: "2px solid #51ACED !important",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "#fff",
        },
      },
    },
    typography: {
      fontFamily: "'K2D', sans-serif",
    },
    palette: {
      background: {
        sellercard: "#000000",
        card: "rgba(255, 255, 255, 0.02)",
        cardinner: "rgba(255, 255, 255, 0.015)",
        tabButton: "rgb(21 18 20)",
        default: "#000000",
        gradientbox: "rgb(15 11 11 / 92%)",
      },
      primary: {
        main: "#ffffff", //black
      },
      secondary: {
        main: "#FFFFFF", //white
        icons: "#FFFFFF", //white
      },
      text: {
        primary: "#FFFFFF", //white
        secondary: "rgba(255, 255, 255, 0.6)", //white
        gray: "rgba(255, 255, 255, 0.6)",
        graydark: "rgba(255, 255, 255, 0.3)",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
